import * as React from 'react'

import { addState, Dispatcher } from '../../lib/add-state'
import { ArtistRolloverFragment, useArtistRolloverInfoLazyQuery } from '../../api/types'
import { ArtistLinkUI } from './ui'

export type Label = {
    name: string
    url: string
}

export type Props = {
    artist?: { discogsId: number; name: string; siteUrl: string } | { name: string } | ArtistRolloverFragment
    displayName?: string
    nameVariation?: string
    tabIndex?: number
    disableArtistLinkHover?: boolean
}

export type State = {
    hover: boolean
    open: boolean
    info?: ArtistRolloverFragment
    disableArtistLinkHover?: boolean
}

export type Action =
    | { type: 'open' }
    | { type: 'close' }
    | { type: 'hover' }
    | { type: 'leave' }
    | { type: 'info'; info: ArtistRolloverFragment }

export function reducer(props: Props, state: State, action: Action): State {
    switch (action.type) {
        case 'open':
            return { ...state, open: true }
        case 'close':
            return { ...state, open: false }
        case 'hover':
            return { ...state, hover: true }
        case 'leave':
            return { ...state, hover: false }
        case 'info':
            return { ...state, info: action.info }
        default:
            return state
    }
}

const ms = 500
export function useSideEffect(props: Props, state: State, dispatch: Dispatcher<Action>): void {
    React.useEffect(
        function (): () => void {
            if (state.hover && !state.disableArtistLinkHover) {
                const t = setTimeout((): void => dispatch({ type: 'open' }), ms)
                return (): void => clearTimeout(t)
            }

            if (state.open) {
                const t = setTimeout((): void => dispatch({ type: 'close' }), ms)
                return (): void => clearTimeout(t)
            }

            return (): void => undefined
        },
        [state.hover],
    )

    const [getInfo, { data }] = useArtistRolloverInfoLazyQuery()
    React.useEffect(
        function (): void {
            if (state.open && props.artist && 'discogsId' in props.artist && !state.info) {
                getInfo({
                    variables: {
                        discogsId: props.artist.discogsId,
                    },
                }).catch((e) => {
                    throw new Error('artist-link getInfo error')
                })
            }
        },
        [state.open, props.artist],
    )

    React.useEffect(
        function () {
            if (data?.artist) {
                dispatch({ type: 'info', info: data.artist })
            }
        },
        [data],
    )
}

function initial(props: Props): State {
    const { disableArtistLinkHover } = props
    const info = props.artist && 'profile' in props.artist ? props.artist : undefined
    return { open: false, hover: false, info, disableArtistLinkHover }
}

export const ArtistLink = addState(ArtistLinkUI, reducer, initial, useSideEffect)
