const variousId = 194

type Artist = {} | { discogsId: number }

type PrimaryArtist = {
    artist?: Artist
}

export function isVarious(artist: PrimaryArtist | Artist | null | undefined): boolean {
    if (!artist) {
        return false
    }

    if ('artist' in artist) {
        return isVarious(artist.artist)
    }

    if ('discogsId' in artist) {
        return artist.discogsId === variousId
    }

    return false
}

export function isAnyVarious(primaryArtists: PrimaryArtist[] | undefined | null): boolean {
    if (!primaryArtists) {
        return false
    }
    return primaryArtists.findIndex(isVarious) >= 0
}
