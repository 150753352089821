import * as React from 'react'

import { useApolloClient } from '@apollo/client'
import { track } from '../../data-layer'

import { prefix } from './prefix'
import { Interaction } from './interaction'
import { usePageContext, PageContext } from './context'
import { PageProvider, PageProps } from './provider'

export { usePageContext } from './context'

export function useInteraction(): (interaction: Interaction) => void {
    const client = useApolloClient()
    const ctx = usePageContext()

    return function interact(interaction: Interaction): void {
        let data = {}

        try {
            data = ctx.interaction?.(client, ctx.discogsId) ?? {}
        } catch (err) {
            // noop
        }

        track({
            event: 'database-page-interactions',
            databaseInteraction: interaction,
            submissionPageType: ctx.objectType,
            ...prefix('databaseSubmission', data),
        })
    }
}

export function createPage(ctx: PageContext): React.ComponentType<PageProps> {
    return function Page(props: PageProps): React.ReactElement {
        const { children, discogsId } = props
        return (
            <PageProvider {...ctx} discogsId={discogsId}>
                {children}
            </PageProvider>
        )
    }
}
