import { capitalize } from '../../capitalize'

type Prefix<P extends string, K extends string> = `${P}${Capitalize<K>}`
type Prefixed<P extends string, T> = {
    [K in keyof T as Prefix<P, string & K>]: T[K]
}

export function prefix<P extends string, T extends Record<string, unknown>>(pfx: P, obj: T): Prefixed<P, T> {
    // @ts-expect-error
    const res: Prefixed<P, T> = {}

    for (const key in obj) {
        const name = capitalize(key)
        const value = obj[key]
        // @ts-expect-error
        res[`${pfx}${name}`] = value
    }
    return res
}
