type WithJoiningText = {
    joiningText?: string | null | undefined
}

/**
 * collate a list of items into a list, using the specified joiningText of the item.
 */
export function collate<T extends WithJoiningText, U>(items: T[], toName: (item: T) => U): (U | string)[] {
    const result = []

    for (let index = 0; index < items.length; index++) {
        const item = items[index]
        const name = toName(item)

        result.push(name)

        if (index < items.length - 1) {
            result.push(joiner(item))
        }
    }

    return result
}

function joiner<T extends WithJoiningText>(item: T): string {
    if (!item.joiningText || item.joiningText === ',') {
        return ', '
    }

    return ` ${item.joiningText} `
}
