import { collate } from './collate'

type LinkedArtist = { discogsId: number; name: string }
type UnlinkedArtist = { name: string }

type Artist = {
    nameVariation?: string | undefined
    displayName?: string | undefined
    joiningText?: string | undefined
    artist?: LinkedArtist | UnlinkedArtist
}

export function collateArtists<T extends Artist, U>(artists: T[], map: (artist: T) => U): (U | string)[] {
    return collate(artists, map)
}

export function artistName<T extends Artist>(artist: T): string {
    if (artist.nameVariation) {
        return artist.nameVariation
    }

    const name = artist.displayName ?? artist.artist?.name ?? ''
    return name.replace(/ \(\d+\)$/, '')
}
